@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 75vh;
  max-width: 900px;
  max-height: 800px;
  padding: 10px 6px 10px 20px;

  @media screen and (max-width: $breakpoint-mobile-landscape-max) and (orientation: portrait) {
    padding-left: 10px;
    padding-right: 0;
  }

  .header {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    color: red;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      flex-wrap: wrap;
    }

    .item {
      font-size: 17px;
      font-weight: bold;
      padding-top: 3px;
      margin: 5px 10px;
      color: #616160;
      cursor: pointer;
      transition: color 0.3s ease;

      &:global(.react-tabs__tab--selected) {
        color: #f6e87e;
        cursor: default;
        outline: none;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #010101;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #f6e87e;
    }
  }
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 2;
}

.title {
  color: #ff9d00;
  font-size: 25px;
  text-align: center;
  font-weight: 700;

  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 20px;
  }

  &:nth-child(n + 2) {
    margin-top: 70px;
  }
}

.p {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;

  &.left {
    text-align: left;
  }
  &.margin {
    margin: 0px;
    padding-top: 20px;
  }

  span {
    color: #f6e87e;
  }
}

.color {
  color: #f6e87e;
}

.paytable-list {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    padding-top: 15px;
  }

  &__title {
    max-width: 150px;
    top: 100%;
    left: 0;
    width: 100%;
    font-size: 28px;
    text-align: center;
    color: #fff;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      font-size: 15px;
    }
    @media screen and (max-width: $breakpoint-mobile-landscape-xs) {
      font-size: 12px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 50%;
    height: 250px;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      height: auto;
      margin-bottom: 25px;
    }

    .img {
      position: relative;
      margin-right: 10px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      @media screen and (max-width: $breakpoint-mobile-landscape-max) {
        width: 50%;
      }
      @media screen and (max-width: $breakpoint-mobile-landscape-xs) {
        margin-right: 3px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-lg) {
        margin-right: 2px;
      }

      img {
        display: block;
        width: 150px;
        height: auto;
        max-height: 300px;

        @media screen and (max-width: $breakpoint-mobile-landscape-max) {
          width: 100%;
        }
      }
    }

    .content {
      align-items: flex-start;
      justify-content: center;
      font-size: 28px;
      color: #f6e87e;

      @media screen and (max-width: $breakpoint-mobile-landscape-max) {
        font-size: calc(2px + 2vw);
      }

      @media screen and (max-width: $breakpoint-mobile-landscape-xs) {
        font-size: 10px;
      }

      @media screen and (max-width: $breakpoint-mobile-portrait-lg) {
        font-size: 8px;
      }

      @media screen and (max-width: $breakpoint-mobile-portrait-md) {
        font-size: 7px;
      }

      .multiplier {
        color: #fff;
      }
    }
  }
}

.gameRules {
  display: block;
  width: 80%;
  margin: 0 auto;

  img {
    margin-left: 20px;
  }

  @media screen and (max-width: $breakpoint-mobile-portrait-max) and (orientation: portrait) {
    width: 100%;
  }

  .title_rule {
    color: #ff9d00;
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      font-size: 20px;
    }

    &:nth-child(n + 2) {
      margin-top: 70px;
    }
  }
}

.payLines {
  display: block;
  width: 80%;
  margin: 0 auto;
  img {
    padding-top: 20px;
    display: block;
    max-width: 80%;
    margin: 0 auto;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;

    @media screen and (max-width: $breakpoint-mobile-portrait-max) and (orientation: portrait) {
      padding-right: 10px;
      max-width: 100%;
    }
  }
}

.feature {
  padding-top: 1rem;

  .fig {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em 20px;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;

    p.caption {
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      margin: 0;
    }
    &__img {
      position: relative;
      display: block;
    }
    &__text {
      position: absolute;
      left: -3px;
      top: 50%;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
  }
  .container {
    position: relative;
    display: inline-block;
  }

  .figcontainer {
    position: relative;
    display: inline-flex;
    width: 200px;
    height: 180px;
    background-position: center;
    background-size: contain;
  }

  .featuretext {
    text-align: center;
    margin: auto;
    max-width: 100%;
    word-wrap: break-word;
    line-height: 1;
    color: #ffbe4e;
    text-shadow: 3px 3px 1px #1b1a1a;
    font-size: 19px;
    padding: 18px 35px;
  }

}

.buttonBehaviors {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    font-weight: 700;
    color: #fff;

    img {
      width: 50px;
      margin-right: 15px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      &.bigger {
        width: 100px;
      }
      &.info_icon {
        width: 45px !important;
        margin-right: 16px !important;
        margin-left: 4px !important;
      }
    }
  }
}
